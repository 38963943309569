import type { InjectionKey, DeepReadonly } from 'vue'

// models
import {
  ProfileData,
  ProfileDetails,
  getProfileDetailsResponse,
  GetProfileDetailsResponse,
} from '@/models/profiles'

// modules
import { isValueOf } from '@/utils/zod'

// repositories
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

// note: testのためexport
export type ProfileStateType = {
  isFetching: boolean
  profile: null | ProfileDetails // MyVketのprofile
}

export const useProfile = () => {
  const repositoryFactory = useRepositoryFactory()
  const profileRepository = repositoryFactory.get('profile')
  const { me } = useAuth()

  const state = reactive<ProfileStateType>({
    isFetching: false,
    profile: null,
  })

  const isOtherUser = computed(() => {
    return state.profile?.vketId !== me.value?.vketId
  })

  const isUnblockUser = computed(() => {
    // NOTE: 未ログイン時(blockStatus=undefined)はブロックしていないとみなす
    return (
      state.profile?.blockStatus !== true &&
      state.profile?.blockedStatus !== true
    )
  })

  /** ブロックしているかどうか */
  const isBlockUser = computed(() => {
    // NOTE: 未ログイン時(blockStatus=undefined)はブロックしていないとみなす
    return state.profile?.blockStatus === true
  })

  /** ブロックされているかどうか */
  const isBlockedUser = computed(() => {
    // NOTE: 未ログイン時(blockStatus=undefined)はブロックしていないとみなす
    return state.profile?.blockedStatus === true
  })

  /**
   * 他ユーザのMyVketのプロフィール取得
   * @param id ユーザID
   */
  const getProfile = async (vketId: string) => {
    try {
      const { data } = await useAsyncData(
        'getProfileById',
        async () =>
          await profileRepository.get.getProfileById({
            vketId,
          })
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (
        !isValueOf<GetProfileDetailsResponse>(
          getProfileDetailsResponse,
          data.value
        )
      ) {
        console.error('An API response is different.')
      }

      state.profile = data.value.profile

      return data.value.profile
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return null
      }
      throw e
    }
  }

  // プロフィールページのmeta情報を設定
  const initProfilePageHead = (
    title: string,
    description: string,
    profile: DeepReadonly<ProfileDetails> | null
  ) => {
    const config = useRuntimeConfig()
    const metaTitle =
      (profile?.name && profile?.name.replace(/[&'`"<>]/g, '')) || title
    useHead({
      title: metaTitle + ' | My Vket',
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: metaTitle + ' | My Vket',
        },
        {
          hid: 'description',
          name: 'description',
          content:
            (profile?.introduction &&
              profile.introduction.replace(/[&'`"<>]/g, '')) ||
            description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            (profile?.introduction &&
              profile.introduction.replace(/[&'`"<>]/g, '')) ||
            description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            profile?.icon.url ||
            `${config.public.NUXT_ENV_URL}/images/no-image-512x512.webp`,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
      ],
    })
  }

  return {
    state: readonly(state),
    isOtherUser,
    isUnblockUser,
    isBlockUser,
    isBlockedUser,
    getProfile,
    initProfilePageHead,
  }
}

export type ProfileComposable = ReturnType<typeof useProfile>

export const profileInjectionKey: InjectionKey<ProfileComposable> =
  Symbol('profile')
